// project import
// import applications from './applications';
// import widget from './widget';
// import formsTables from './forms-tables';
// import chartsMap from './charts-map';
import other from './other';
import pages from './pages';
import shoutOutManagement from './shout-out-management';
import adsGroup from './ads-group';
import likesAndComments from './likes-and-comments';
import rejectedList from './rejected-list';
import businessManagement from './business-management';
import TagsMangement from './tags-management';
import dashboard1 from './dashboard1';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [
    dashboard1,
    pages,
    adsGroup,
    shoutOutManagement,
    TagsMangement,
    rejectedList,
    businessManagement,
    likesAndComments,
    other
  ]
};

export const premiumMenu  = {
  items: [
    adsGroup,
    shoutOutManagement,
    rejectedList,
    businessManagement,
    other
  ]
};

export default menuItems;
