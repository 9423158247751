// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { UsergroupDeleteOutlined ,UserAddOutlined, DollarOutlined, LoginOutlined, PhoneOutlined, RocketOutlined } from '@ant-design/icons';
import AdsClickIcon from '@mui/icons-material/AdsClick';

// icons
const icons = { UsergroupDeleteOutlined, UserAddOutlined, DollarOutlined, LoginOutlined, PhoneOutlined, RocketOutlined, AdsClickIcon };

// ==============================|| MENU ITEMS - PAGES ||============================== //

const adsGroup = {
  id: 'group-ads',
  title: <FormattedMessage id="ads" />,
  type: 'group',
  children: [
    {
      id: 'ads-reports',
      title: <FormattedMessage id="ads-reports" />,
      type: 'item',
      url: '/ads-reports',
      icon: icons.AdsClickIcon,
    },
    {
      id: 'ads',
      title: <FormattedMessage id="ads" />,
      type: 'item',
      url: '/ads',
      icon: icons.AdsClickIcon
    }
    
  ]
};

export default adsGroup;
