import { lazy } from 'react';

// project import
// import ErrorBoundary from './ErrorBoundary';
import Loadable from 'components/Loadable';
import DashboardLayout from 'layout/Dashboard';
import PagesLayout from 'layout/Pages';
import SimpleLayout from 'layout/Simple';
import { SimpleLayoutType } from 'config';

// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard/default')));
const AdminUsers = Loadable(lazy(() => import('pages/admin-users/admin-users')));
const AppUsers = Loadable(lazy(() => import('pages/app-users/app-users')));
const ShoutOut = Loadable(lazy(() => import('pages/shout-out')));
const ShootOutCategories = Loadable(lazy(() => import('pages/shoot-out-categories')));
const BussinessArticlevideo = Loadable(lazy(() => import('pages/bussiness-article-video')));
const CommentMessage = Loadable(lazy(() => import('pages/comment-message')));
const TagsManagement = Loadable(lazy(() => import('pages/tags-management')));
const Likes = Loadable(lazy(() => import('pages/likes')));
const RejectedBussiness = Loadable(lazy(() => import('pages/rejected-bussiness')));
const RejectedEvents = Loadable(lazy(() => import('pages/rejected-events')));
const RejectedCoupon = Loadable(lazy(() => import('pages/rejected-coupon')));
const RejectedShoutOut = Loadable(lazy(() => import('pages/rejected-shout-out')));
const RejectedArticleVideo = Loadable(lazy(() => import('pages/rejected-article-video')));
const Ads = Loadable(lazy(() => import('pages/ads')));
const BusinessCategory = Loadable(lazy(() => import('pages/business-category')));
const BusinessPlaces = Loadable(lazy(() => import('pages/business-places')));
const EventCategory = Loadable(lazy(() => import('pages/event-category')));
const EventManagement = Loadable(lazy(() => import('pages/event-management')));
const CouponManagement = Loadable(lazy(() => import('pages/coupon-management')));
const AdsReports = Loadable(lazy(() => import('pages/ads-reports')));
const Notifications = Loadable(lazy(() => import('pages/notifications/notifications')));
const ResourceManagement = Loadable(lazy(() => import('pages/resource-management')));
const Settings = Loadable(lazy(() => import('pages/settings/settings')));
const Map = Loadable(lazy(() => import('pages/map')));

// pages routing
const AuthLogin = Loadable(lazy(() => import('pages/auth/login')));
const AuthRegister = Loadable(lazy(() => import('pages/auth/register')));
const AuthForgotPassword = Loadable(lazy(() => import('pages/auth/forgot-password')));
const AuthResetPassword = Loadable(lazy(() => import('pages/auth/reset-password')));
const AuthCheckMail = Loadable(lazy(() => import('pages/auth/check-mail')));
const AuthCodeVerification = Loadable(lazy(() => import('pages/auth/code-verification')));

const AppContactUS = Loadable(lazy(() => import('pages/contact-us')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        {
          path: 'dashboard',
          element: <DashboardDefault />,
        },
        {
          path: 'dashboard',
          element: <DashboardDefault />
        },
        {
          path: 'admin-users',
          element: <AdminUsers />
        },
        {
          path: 'app-users',
          element: <AppUsers />
        },
        
        {
          path: 'shout-out',
          element: <ShoutOut />
        },
       
        {
          path: 'shout-out-categories',
          element: <ShootOutCategories />
        },
        {
          path: 'bussiness-article-video',
          element: <BussinessArticlevideo />
        },
        {
          path: 'comment-message',
          element: <CommentMessage />
        },
        {
          path: 'tags-management',
          element: <TagsManagement />
        },
        {
          path: 'likes',
          element: <Likes />
        },
        {
          path: 'rejected-bussiness',
          element: <RejectedBussiness />
        },
       
        {
          path: 'rejected-events',
          element: <RejectedEvents />
        },
  
        {
          path: 'rejected-coupon',
          element: <RejectedCoupon />
        },
        {
          path: 'rejected-shout-out',
          element: <RejectedShoutOut />
        },
        {
          path: 'rejected-article-video',
          element: <RejectedArticleVideo />
        },
        {
          path: 'ads',
          element: <Ads />
        },
        {
          path: 'ads-reports',
          element: <AdsReports />
        },
        {
          path: 'resource-management',
          element: <ResourceManagement />
        },
        {
          path: 'notifications',
          element: <Notifications />
        },
        {
          path: 'settings',
          element: <Settings />
        },
        {
          path: 'business-category',
          element: <BusinessCategory />
        },
        {
          path: 'business-places',
          element: <BusinessPlaces />
        },
     
        {
          path: 'event-category',
          element: <EventCategory />
        },
     
        {
          path: 'event-management',
          element: <EventManagement />
        },
        {
          path: 'coupon-management',
          element: <CouponManagement />
        },
        {
          path: 'map',
          element: <Map />
        },
        // {
        //   path: 'sample-page',
        //   element: <SamplePage />
        // },
        // {
        //   path: 'pricing',
        //   element: <PricingPage />
        // }
      ]
    },
    // {
    //   path: '/maintenance',
    //   element: <PagesLayout />,
    //   children: [
    //     {
    //       path: '404',
    //       element: <MaintenanceError />
    //     },
    //     {
    //       path: '500',
    //       element: <MaintenanceError500 />
    //     },
    //     {
    //       path: 'under-construction',
    //       element: <MaintenanceUnderConstruction />
    //     },
    //     {
    //       path: 'coming-soon',
    //       element: <MaintenanceComingSoon />
    //     }
    //   ]
    // },
    {
      path: '/auth',
      element: <PagesLayout />,
      children: [
        {
          path: 'login',
          element: <AuthLogin />
        },
        {
          path: 'register',
          element: <AuthRegister />
        },
        {
          path: 'forgot-password',
          element: <AuthForgotPassword />
        },
        {
          path: 'reset-password',
          element: <AuthResetPassword />
        },
        {
          path: 'check-mail',
          element: <AuthCheckMail />
        },
        {
          path: 'code-verification',
          element: <AuthCodeVerification />
        }
      ]
    },
    {
      path: '/',
      element: <SimpleLayout layout={SimpleLayoutType.SIMPLE} />,
      children: [
        {
          path: 'contact-us',
          element: <AppContactUS />
        }
      ]
    }
  ]
};

export default MainRoutes;
