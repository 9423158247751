// import { lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';

// project import
import MainRoutes from './MainRoutes';
import LoginRoutes from './LoginRoutes';
// import ComponentsRoutes from './ComponentsRoutes';

// import SimpleLayout from 'layout/Simple';
// import Loadable from 'components/Loadable';

// import { SimpleLayoutType } from 'config';

// render - landing page
// const PagesLanding = Loadable(lazy(() => import('pages/landing')));

// ==============================|| ROUTING RENDER ||============================== //

// const router = createBrowserRouter(
//   [
//     {
//       path: '/',
//       element: <SimpleLayout layout={SimpleLayoutType.LANDING} />,
//       children: [
//         {
//           index: true,
//           element: <PagesLanding />
//         }
//       ]
//     },
//     LoginRoutes,
//     ComponentsRoutes,
//     MainRoutes
//   ],
//   { basename: process.env.REACT_APP_BASE_NAME }
// );

const router = createBrowserRouter([LoginRoutes, MainRoutes], { basename: process.env.REACT_APP_BASE_NAME });

export default router;
