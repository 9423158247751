// This is example of menu item without group for horizontal layout. There will be no children.

// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { TagsOutlined} from '@ant-design/icons';

// icons
const icons = {
  TagsOutlined
};

// ==============================|| MENU ITEMS - SAMPLE PAGE ||============================== //

const TagsMangement = {
  id: 'tags-management',
  title: <FormattedMessage id="tags-management" />,
  type: 'group',
  children: [
    {
      id: 'tags-management',
      title: <FormattedMessage id="tags-management" />,
      type: 'item',
      url: '/tags-management',
      icon: icons.TagsOutlined
    }
  ]
};

export default TagsMangement;
