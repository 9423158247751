// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { CommentOutlined} from '@mui/icons-material';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';

// icons
const icons = { CommentOutlined , ThumbUpAltIcon , PlaylistRemoveIcon};
// ==============================|| MENU ITEMS - PAGES ||============================== //

const rejectedList = {
  id: 'rejected-group',
  title: <FormattedMessage id="rejected-list" />,
  type: 'group',
  children: [
    {
      id: 'rejected-bussiness',
      title: <FormattedMessage id="rejected-bussiness" />,
      type: 'item',
      url: '/rejected-bussiness',
      icon: icons.PlaylistRemoveIcon
    },
    {
      id: 'rejected-events',
      title: <FormattedMessage id="rejected-events" />,
      type: 'item',
      url: '/rejected-events',
      icon: icons.PlaylistRemoveIcon
    },
    {
      id: 'rejected-coupon',
      title: <FormattedMessage id="rejected-coupon" />,
      type: 'item',
      url: '/rejected-coupon',
      icon: icons.PlaylistRemoveIcon
    },
    {
      id: 'rejected-shout-out',
      title: <FormattedMessage id="rejected-shout-out" />,
      type: 'item',
      url: '/rejected-shout-out',
      icon: icons.PlaylistRemoveIcon
    },
    {
      id: 'rejected-article-video',
      title: <FormattedMessage id="rejected-article-video" />,
      type: 'item',
      url: '/rejected-article-video',
      icon: icons.PlaylistRemoveIcon
    }
  ]
};

export default rejectedList;
