// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { CommentOutlined} from '@mui/icons-material';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';

// icons
const icons = { CommentOutlined , ThumbUpAltIcon };
// ==============================|| MENU ITEMS - PAGES ||============================== //

const likesAndComments = {
  id: 'likes-and-comments-group',
  title: <FormattedMessage id="likes-and-comments" />,
  type: 'group',
  children: [
    {
      id: 'likes',
      title: <FormattedMessage id="likes" />,
      type: 'item',
      url: '/likes',
      icon: icons.ThumbUpAltIcon
    },
    {
      id: 'comment-message',
      title: <FormattedMessage id="comment-message" />,
      type: 'item',
      url: '/comment-message',
      icon: icons.CommentOutlined
    }
  ]
};

export default likesAndComments;
