// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { UsergroupDeleteOutlined ,UserAddOutlined, DollarOutlined, LoginOutlined, PhoneOutlined, RocketOutlined } from '@ant-design/icons';
import AdsClickIcon from '@mui/icons-material/AdsClick';

// icons
const icons = { UsergroupDeleteOutlined, UserAddOutlined, DollarOutlined, LoginOutlined, PhoneOutlined, RocketOutlined, AdsClickIcon };

// ==============================|| MENU ITEMS - PAGES ||============================== //

const pages = {
  id: 'group-pages',
  title: <FormattedMessage id="users" />,
  type: 'group',
  children: [
    // {
    //   id: 'shout-outmanagement',
    //   title: <FormattedMessage id="shout-outmanagement" />,
    //   type: 'collapse',
    //   icon: icons.LoginOutlined,
    //   children: [
    //     {
    //       id: 'shoutout',
    //       title: <FormattedMessage id="shoutout" />,
    //       type: 'item',
    //       url: '/shout-out',
    //     },
    //     {
    //       id: 'register',
    //       title: <FormattedMessage id="register" />,
    //       type: 'item',
    //       url: '/auth/register',
    //       target: true
    //     },
    //     {
    //       id: 'forgot-password',
    //       title: <FormattedMessage id="forgot-password" />,
    //       type: 'item',
    //       url: '/auth/forgot-password',
    //       target: true
    //     },
    //     {
    //       id: 'reset-password',
    //       title: <FormattedMessage id="reset-password" />,
    //       type: 'item',
    //       url: '/auth/reset-password',
    //       target: true
    //     },
    //     {
    //       id: 'check-mail',
    //       title: <FormattedMessage id="check-mail" />,
    //       type: 'item',
    //       url: '/auth/check-mail',
    //       target: true
    //     },
    //     {
    //       id: 'code-verification',
    //       title: <FormattedMessage id="code-verification" />,
    //       type: 'item',
    //       url: '/auth/code-verification',
    //       target: true
    //     }
    //   ]
    // },
    // {
    //   id: 'maintenance',
    //   title: <FormattedMessage id="maintenance" />,
    //   type: 'collapse',
    //   icon: icons.RocketOutlined,
    //   isDropdown: true,
    //   children: [
    //     {
    //       id: 'error-404',
    //       title: <FormattedMessage id="error-404" />,
    //       type: 'item',
    //       url: '/maintenance/404',
    //       target: true
    //     },
    //     {
    //       id: 'error-500',
    //       title: <FormattedMessage id="error-500" />,
    //       type: 'item',
    //       url: '/maintenance/500',
    //       target: true
    //     },
    //     {
    //       id: 'coming-soon',
    //       title: <FormattedMessage id="coming-soon" />,
    //       type: 'item',
    //       url: '/maintenance/coming-soon',
    //       target: true
    //     },
    //     {
    //       id: 'under-construction',
    //       title: <FormattedMessage id="under-construction" />,
    //       type: 'item',
    //       url: '/maintenance/under-construction',
    //       target: true
    //     }
    //   ]
    // },
    // {
    //   id: 'contact-us',
    //   title: <FormattedMessage id="contact-us" />,
    //   type: 'item',
    //   url: '/contact-us',
    //   icon: icons.PhoneOutlined,
    //   target: true
    // },
    {
      id: 'admin-users',
      title: <FormattedMessage id="admin-users" />,
      type: 'item',
      url: '/admin-users',
      icon: icons.UserAddOutlined
    },
    {
      id: 'app-users',
      title: <FormattedMessage id="app-users" />,
      type: 'item',
      url: '/app-users',
      icon: icons.UsergroupDeleteOutlined
    },
    // {
    //   id: 'pricing',
    //   title: <FormattedMessage id="pricing" />,
    //   type: 'item',
    //   url: '/pricing',
    //   icon: icons.DollarOutlined
    // }
  ]
};

export default pages;
