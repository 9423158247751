// third-party
import { FormattedMessage } from 'react-intl';

// assets
import CampaignIcon from '@mui/icons-material/Campaign';

// icons
const icons = { CampaignIcon };

// ==============================|| MENU ITEMS - PAGES ||============================== //

const userRole = localStorage.getItem('UserRole');

let shoutoutManagementItems = [
  {
    id: 'shoutout',
    title: <FormattedMessage id="shoutout" />,
    type: 'item',
    url: '/shout-out',
    icon: icons.CampaignIcon
  },
  {
    id: 'shout-out-categories',
    title: <FormattedMessage id="shout-out-categories" />,
    type: 'item',
    url: '/shout-out-categories',
    icon: icons.CampaignIcon
  },
  {
    id: 'bussiness-article-video',
    title: <FormattedMessage id="bussiness-article-video" />,
    type: 'item',
    url: '/bussiness-article-video',
    icon: icons.CampaignIcon
  }
];

if (userRole === 'Premium') {
  shoutoutManagementItems = shoutoutManagementItems.filter(item => item.id !== 'shout-out-categories');
}

const shoutOutManagement = {
  id: 'shout-out-group',
  title: <FormattedMessage id="shout-out-management" />,
  type: 'group',
  children: shoutoutManagementItems
};

export default shoutOutManagement;
