// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { CommentOutlined } from '@mui/icons-material';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import CategoryIcon from '@mui/icons-material/Category';

// icons
const icons = { CommentOutlined, ThumbUpAltIcon, CategoryIcon };
// ==============================|| MENU ITEMS - PAGES ||============================== //

const userRole = localStorage.getItem('UserRole');

let businessManagementItems = [
  {
    id: 'business-category',
    title: <FormattedMessage id="business-category" />,
    type: 'item',
    url: '/business-category',
    icon: icons.CategoryIcon
  },
  {
    id: 'business-places',
    title: <FormattedMessage id="business-places" />,
    type: 'item',
    url: '/business-places',
    icon: icons.CategoryIcon
  },
  {
    id: 'event-category',
    title: <FormattedMessage id="event-category" />,
    type: 'item',
    url: '/event-category',
    icon: icons.CategoryIcon
  },
  {
    id: 'event-management',
    title: <FormattedMessage id="event-management" />,
    type: 'item',
    url: '/event-management',
    icon: icons.CategoryIcon
  },
  {
    id: 'coupon-management',
    title: <FormattedMessage id="coupon-management" />,
    type: 'item',
    url: '/coupon-management',
    icon: icons.CategoryIcon
  }
];

// Check if the user role is 'Premium' and exclude 'business-category' item accordingly
if (userRole === 'Premium') {
  businessManagementItems = businessManagementItems.filter(item => item.id !== 'business-category'&& item.id !== 'event-category');
}

const businessManagement = {
  id: 'business-management-group',
  title: <FormattedMessage id="business-management" />,
  type: 'group',
  children: businessManagementItems
};

export default businessManagement;
