// This is example of menu item without group for horizontal layout. There will be no children.

// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { NotificationOutlined , SettingOutlined , UserOutlined} from '@ant-design/icons';

// icons
const icons = {
  NotificationOutlined , SettingOutlined , UserOutlined
};

// ==============================|| MENU ITEMS - SAMPLE PAGE ||============================== //

const other = {
  id: 'other',
  title: <FormattedMessage id="other" />,
  type: 'group',
  children: [
    {
      id: 'notifications',
      title: <FormattedMessage id="send-app-notifications" />,
      type: 'item',
      url: '/notifications',
      icon: icons.NotificationOutlined
    } ,
    {
      id: 'resource-management',
      title: <FormattedMessage id="resource-management" />,
      type: 'item',
      url: '/resource-management',
      icon: icons.UserOutlined
    },
    {
      id: 'settings',
      title: <FormattedMessage id="settings" />,
      type: 'item',
      url: '/settings',
      icon: icons.SettingOutlined
    }
  ]
};

export default other;
